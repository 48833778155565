import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

function ResetPasswordForm() {
  const { uidb64, token } = useParams();
  const navigate = useNavigate();
  const [form, setForm] = useState({ password: "", confirmPassword: "" });
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (form.password !== form.confirmPassword) {
      setError("Οι κωδικοί δεν ταιριάζουν.");
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/password-reset-confirm/${uidb64}/${token}/`, {
        new_password: form.password,
        confirm_new_password: form.confirmPassword,
      });
      setMessage("Ο κωδικός άλλαξε με επιτυχία.");
      setError(null);
      navigate("/login");
    } catch (err) {
      setError("Η αλλαγή κωδικού απέτυχε. Παρακαλώ προσπαθήστε ξανά.");
      setMessage("");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 rounded">
      <div className="mb-3">
        <label className="block text-sm font-medium">Νέος Κωδικός</label>
        <div className="relative">
          <input
            value={form.password}
            onChange={(e) => setForm({ ...form, password: e.target.value })}
            required
            type={showPassword ? "text" : "password"}
            placeholder="Εισάγετε τον νέο κωδικό"
            className="mt-1 block w-full px-3 py-2 border border-primary"
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-primary"
          >
            {showPassword ? "Απόκρυψη" : "Εμφάνιση"}
          </button>
        </div>
      </div>
      <div className="mb-3">
        <label className="block text-sm font-medium">Επιβεβαίωση Νέου Κωδικού</label>
        <div className="relative">
          <input
            value={form.confirmPassword}
            onChange={(e) => setForm({ ...form, confirmPassword: e.target.value })}
            required
            type={showPassword ? "text" : "password"}
            placeholder="Επιβεβαιώστε τον νέο κωδικό"
            className="mt-1 block w-full px-3 py-2 border border-primary"
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-primary"
          >
            {showPassword ? "Απόκρυψη" : "Εμφάνιση"}
          </button>
        </div>
      </div>
      {message && <div className="text-success text-sm mt-1">{message}</div>}
      {error && <div className="text-error text-sm mt-1">{error}</div>}
      <button
        type="submit"
        className="btn btn-info mt-3 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Αλλαγή Κωδικού
      </button>
    </form>
  );
}

export default ResetPasswordForm;
