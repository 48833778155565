import React, {useState, useEffect, useContext  } from "react";

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { getUser, useUserActions } from "../hooks/user.actions";
import { Menu, Transition } from '@headlessui/react';
import ThemeContext from '../helpers/context/themeContext'; // Adjust the import path as necessary

function Navigationbar() {
  const navigate = useNavigate();
  const user = getUser();

  const handleLogout = () => {
    localStorage.removeItem("auth");
    navigate("/login/");
  };

  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);
  

  return (
    <div className="navbar bg-neutral text-neutral-content grow-0">
      <div className="flex-1">
        <a href="/" className="btn btn-ghost text-xl">Public Contracts</a>
      </div>
      <div className="flex-none">
      <label className="cursor-pointer grid place-items-center mr-2">
        <input type="checkbox" checked={theme === 'night'} onChange={toggleTheme} className="toggle theme-controller bg-base-content row-start-1 col-start-1 col-span-2 border-none  "/>
        <svg className="col-start-1 row-start-1 stroke-base-100 fill-base-100" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="5"/><path d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4"/></svg>
        <svg className="col-start-2 row-start-1 stroke-base-100 fill-base-100" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg>
      </label>
        <div className="dropdown dropdown-end">
          <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar ">
            <div className="w-10 rounded-full bg-base-300">
              <img alt="Tailwind CSS Navbar component" src='/user.png' />
            </div>
          </div>
          <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-neutral text-neutral-content rounded-box w-52">
           <li>
              <a href= {`/`} className="justify-between">
                Κατηγορίες
              </a>
            </li>
            <li>
              <a href= {`/profile/${user.id}/`} className="justify-between">
                Προφίλ
              </a>
            </li>
            <li><a onClick={handleLogout}>Έξοδος</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navigationbar;