import React, { useEffect, useState } from 'react';
import axiosService from '../../helpers/axios';
import { useUserActions } from '../../hooks/user.actions';
import { useToaster } from '../../helpers/context/toasterContext';
import Preloader from '../Preloader';
import { getUser } from '../../hooks/user.actions';

const CategoryList = ({ onCategorySelect }) => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [topLevelSubscriptions, setTopLevelSubscriptions] = useState([]);
  const [initialTopLevelSubscriptions, setInitialTopLevelSubscriptions] = useState([]);
  const [subscribe, setSubscribe] = useState([]);
  const [unsubscribe, setUnsubscribe] = useState([]);
  const [error, setError] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(null); // 'success' or 'error'
  const [statusMessage, setStatusMessage] = useState('');
  const baseURL = process.env.REACT_APP_API_URL;
  const userActions = useUserActions();
  const { setToaster } = useToaster();
  const user = getUser();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setIsLoading(true);
        const categoryResponse = await axiosService.get(`${baseURL}/newsletter/category/newsletter-categories/`);
        setCategories(categoryResponse.data);

        const subscriptionResponse = await axiosService.get(`${baseURL}/newsletter-subscriptions/subscriptions/`);
        const subscribedIds = Array.isArray(subscriptionResponse.data)
          ? subscriptionResponse.data.map(sub => sub.category)
          : [];
        
        setTopLevelSubscriptions(subscribedIds);
        setInitialTopLevelSubscriptions(subscribedIds);
        
        // Delay setting isLoading to false to ensure minimum loading time
        setTimeout(() => {
          setIsLoading(false);
          // Trigger fade in after loading is complete
          setTimeout(() => setIsVisible(true), 50);
        }, 300);
      } catch (error) {
        console.error('Error fetching categories or subscriptions:', error);
        setError('Failed to fetch categories or subscriptions');
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, [baseURL]);

  useEffect(() => {
    const subscribeList = topLevelSubscriptions.filter(id => !initialTopLevelSubscriptions.includes(id));
    const unsubscribeList = initialTopLevelSubscriptions.filter(id => !topLevelSubscriptions.includes(id));
    setSubscribe(subscribeList);
    setUnsubscribe(unsubscribeList);
  }, [topLevelSubscriptions, initialTopLevelSubscriptions]);

  const handleCheckboxChange = (categoryId) => {
    // Reset status when user makes new changes
    setSubmitStatus(null);
    setStatusMessage('');
    setTopLevelSubscriptions((prevSubscriptions) =>
      prevSubscriptions.includes(categoryId)
        ? prevSubscriptions.filter((id) => id !== categoryId)
        : [...prevSubscriptions, categoryId]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus(null); // Reset status before new submission
  
    try {
      await userActions.updateNewsletterSubscriptions({ subscribe, unsubscribe });
      
      // Check if user's membership is expired
      const isMembershipExpired = user.membership?.status?.toLowerCase() === 'expired';
      
      if (isMembershipExpired) {
        setToaster({
          show: true,
          type: 'warning',
          message: 'Οι προτιμήσεις σας ενημερώθηκαν, αλλά θα χρειαστεί να ανανεώσετε τη συνδρομή σας για να λαμβάνετε ειδοποιήσεις.',
          title: 'Απαιτείται Ανανέωση Συνδρομής',
          autoHide: true,
        });
        setStatusMessage('Οι προτιμήσεις σας ενημερώθηκαν με επιτυχία, αλλά για να λαμβάνετε ειδοποιήσεις θα χρειαστεί να ανανεώσετε τη συνδρομή σας στη σελίδα του λογαριασμού σας.');
      } else {
        setToaster({
          show: true,
          type: 'success',
          message: 'Οι προτιμήσεις σας ενημερώθηκαν με επιτυχία',
          title: 'Επιτυχής Ενημέρωση',
          autoHide: true,
        });
        setStatusMessage('Οι προτιμήσεις σας ενημερώθηκαν με επιτυχία. Από την επόμενη ημέρα θα λαμβάνετε ειδοποιήσεις για νέους διαγωνισμούς στις κατηγορίες που επιλέξατε');
      }
      
      setInitialTopLevelSubscriptions(topLevelSubscriptions);
      setSubmitStatus('success');
    } catch (error) {
      console.error('Error updating subscriptions:', error);
      const errorMessage = error?.response?.data?.detail || 'Failed to update subscriptions';
      setError(errorMessage);
      setToaster({
        type: 'error',
        message: errorMessage,
        show: true,
        title: 'Αποτυχία Ενημέρωσης',
      });
      setSubmitStatus('error');
      setStatusMessage(errorMessage);
    }
  };

  const hasChanges = () => {
    return subscribe.length > 0 || unsubscribe.length > 0;
  };

  if (error) {
    return <div className="p-5 text-red-500">{error}</div>;
  }

  return (
    <div className="p-5">
      {isLoading ? (
        <div className="flex justify-center items-center min-h-[400px] transition-opacity duration-300 ease-in-out">
          <Preloader />
        </div>
      ) : (
        <form 
          onSubmit={handleSubmit}
          className={`transition-all duration-300 ease-in-out ${
            isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
          }`}
        >
          <ul className="space-y-1">
            {categories.map((category, index) => (
              <li 
                key={category.id} 
                className="border-solid border-b-[1px] border-base-content !m-0 transition-opacity duration-300 ease-in-out"
                style={{ 
                  transitionDelay: `${index * 50}ms`,
                  opacity: isVisible ? 1 : 0,
                  transform: isVisible ? 'translateY(0)' : 'translateY(10px)'
                }}
              >
                <label className="category-label cursor-pointer text-left block w-full h-full p-2 hover:bg-base-200 hover:transition-colors duration-200">
                  <input
                    type="checkbox"
                    checked={topLevelSubscriptions.includes(category.id)}
                    onChange={() => handleCheckboxChange(category.id)}
                    className="category-checkbox checkbox checkbox-sm checkbox-info toggle-info mt-1 mr-5" 
                  />
                  <span className="category-label-span relative bottom-1">{category.name}</span>
                </label>
              </li>
            ))}
          </ul>
          <button 
            className="btn btn-info mt-5 transition-all duration-200 hover:scale-105" 
            type="submit"
            disabled={!hasChanges()}
          >
            Επιλογή
          </button>
          {submitStatus === 'success' && statusMessage && (
            <div className={`mt-4 p-3 rounded-lg transition-all duration-300 border ${
              user.membership?.status?.toLowerCase() === 'expired'
                ? 'bg-warning/10 text-base-content border-warning font-bold'
                : 'bg-success/10 text-content border-success'
            }`}>
              <p className="text-sm">{statusMessage}</p>
            </div>
          )}
          {submitStatus === 'error' && statusMessage && (
            <div className="mt-4 p-3 rounded-lg transition-all duration-300 bg-error/10 text-error-content border border-error">
              <p className="text-sm">{statusMessage}</p>
            </div>
          )}
        </form>
      )}
    </div>
  );
};

export default CategoryList;