import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUserActions, getUser } from "../hooks/user.actions";
import LoginForm from "../components/authentication/LoginForm";

const VerifyEmail = () => {
  const { token } = useParams();
  const [message, setMessage] = useState("Επεξεργασία...");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState("processing"); // "processing", "success", "error"
  const userActions = useUserActions();
  const navigate = useNavigate();
  const isFirstLoad = useRef(true);

  useEffect(() => {
    const user = getUser();
    if (isFirstLoad.current && user) {
      isFirstLoad.current = false;
      setIsLoggedIn(true);
      verifyEmail(token);
    } else {
      setMessage("Παρακαλώ συνδεθείτε για να επαληθεύσετε το email σας.");
      setVerificationStatus("error");
    }
  }, [token]);

  const verifyEmail = async (token) => {
    try {
      const response = await userActions.verifyEmail(token);
      if (response.error) {
        setMessage(response.error);
        setVerificationStatus("error");
      } else {
        setMessage("Η επαλήθευση ολοκληρώθηκε με επιτυχία. Ανακατεύθυνση...");
        setVerificationStatus("success");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      setMessage("Η επαλήθευση απέτυχε. Παρακαλώ προσπαθήστε ξανά.");
      setVerificationStatus("error");
    }
  };

  const handleLogin = async (data) => {
    try {
      const user = await userActions.login(data);
      setIsLoggedIn(true);
      if (user.is_verified) {
        navigate("/");
      } else {
        verifyEmail(token);
      }
    } catch (error) {
      setMessage("Η σύνδεση απέτυχε. Παρακαλώ προσπαθήστε ξανά.");
      setVerificationStatus("error");
    }
  };

  const statusIcons = {
    processing: "⌛",
    success: "✅",
    error: "⚠️"
  };

  return (
    <div className="min-h-screen bg-base-100 flex items-center justify-center p-4">
      <div className="w-full max-w-2xl">
        <div className="bg-base-100 rounded-xl shadow-lg overflow-hidden">
          {/* Header Section */}
          <div className="bg-gradient-to-r from-primary/10 via-primary/5 to-base-100 p-6">
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 rounded-full bg-primary/10 flex items-center justify-center text-2xl">
                {statusIcons[verificationStatus]}
              </div>
              <h2 className="mt-4 text-2xl font-semibold text-base-content">
                Επαλήθευση Email
              </h2>
              <p className="mt-2 text-base-content/70 text-center max-w-sm">
                {verificationStatus === "processing" && "Επεξεργαζόμαστε το αίτημα επαλήθευσης"}
                {verificationStatus === "success" && "Η επαλήθευση ολοκληρώθηκε"}
                {verificationStatus === "error" && "Απαιτείται σύνδεση"}
              </p>
            </div>
          </div>

          {/* Content Section */}
          <div className="p-6 space-y-6">
            {/* Status Card */}
            <div className="bg-base-200 rounded-lg p-6">
              <div className={`text-center space-y-4 ${
                verificationStatus === "processing" ? "text-base-content/70" :
                verificationStatus === "success" ? "text-success" :
                "text-error"
              }`}>
                {/* Status Message */}
                <div className="text-lg font-medium">
                  {message}
                </div>

                {/* Progress Indicator */}
                {verificationStatus === "processing" && (
                  <div className="flex justify-center">
                    <span className="loading loading-spinner loading-lg text-primary"></span>
                  </div>
                )}
              </div>
            </div>

            {/* Login Form (if needed) */}
            {!isLoggedIn && (
              <div className="bg-base-200 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-base-content mb-4">
                  Σύνδεση
                </h3>
                <p className="text-base-content/70 mb-4">
                  Παρακαλώ συνδεθείτε για να συνεχίσετε με την επαλήθευση του email σας.
                </p>
                <LoginForm onSubmit={handleLogin} />
              </div>
            )}

            {/* Help Note */}
            <div className="text-xs text-base-content/50 text-center">
              Αν αντιμετωπίζετε προβλήματα, παρακαλούμε επικοινωνήστε με την υποστήριξη
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;