import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import Layout from "../components/Layout";
import NoticesList from '../components/notices/DailyNoticesList';
import axiosService from '../helpers/axios';
import { getUser } from '../hooks/user.actions';

const DailyNoticesPage = () => {
  const { cpv, date } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [notices, setNotices] = useState([]);
  const [noticesCount, setNoticesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const user = getUser();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const subcategoriesParam = searchParams.get('subcategories');
    const pageParam = searchParams.get('page');

    if (cpv && date) {
      fetchNotices(
        cpv, 
        date, 
        subcategoriesParam ? subcategoriesParam.split(',').filter(Boolean) : [], 
        pageParam ? Number(pageParam) : 1
      );
    }
  }, [cpv, date, location.search]);

  const fetchNotices = async (cpv, date, subcategories = [], page = 1) => {
    setIsLoading(true);
    try {
      const params = {
        cpv,
        date,
        limit: 15,
        page,
        ...(subcategories.length > 0 && { subcategories: subcategories.join(',') })
      };

      const response = await axiosService.get(`${process.env.REACT_APP_API_URL}/tenders/notices/by-cpv-date/`, { params });
      
      setNotices(response.data.results.notices);
      setNoticesCount(response.data.count);
      setNextPage(response.data.next);
      setPreviousPage(response.data.previous);
      setError(null);
      window.scrollTo(0, 0);
    } catch (error) {
      console.error('Error fetching notices:', error);
      if (error.response?.status === 403) {
        setError('membership_expired');
      } else {
        setError('Failed to fetch notices');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFilterOptions = async (cpv, date) => {
    try {
      const response = await axiosService.get(`${process.env.REACT_APP_API_URL}/tenders/notices/filter-options/`, {
        params: { cpv, date }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching filter options:', error);
      return {};
    }
  };

  const updateURL = (subcategories, page) => {
    const searchParams = new URLSearchParams(location.search);
    if (subcategories.length > 0) {
      searchParams.set('subcategories', subcategories.join(','));
    } else {
      searchParams.delete('subcategories');
    }
    searchParams.set('page', page);
    navigate({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  const renderExpiredMembershipAlert = () => {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="alert alert-error shadow-lg flex justify-between items-center">
          <div className="flex items-center gap-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
            <div className="flex flex-col">
              <h3 className="font-bold">Η συνδρομή σας έχει λήξει!</h3>
              <div className="text-sm">Ανανεώστε τη συνδρομή σας για να συνεχίσετε να λαμβάνετε ειδοποιήσεις.</div>
            </div>
          </div>
          <Link to={`/profile/${user.id}/subscription/`} className="btn btn-sm ml-4">
            Ανανέωση Συνδρομής
          </Link>
        </div>
      </div>
    );
  };

  if (error === 'membership_expired') {
    return (
      <Layout>
        {renderExpiredMembershipAlert()}
      </Layout>
    );
  }

  if (error && error !== 'membership_expired') {
    return (
      <Layout>
        <div className="p-4 text-error">{error}</div>
      </Layout>
    );
  }

  return (
    <Layout>
      <NoticesList 
        notices={notices}
        noticesCount={noticesCount}
        isLoading={isLoading}
        nextPage={nextPage}
        previousPage={previousPage}
        cpv={cpv}
        date={date}
        onFetchNotices={fetchNotices}
        onFetchFilterOptions={fetchFilterOptions}
        onUpdateURL={updateURL}
      />
    </Layout>
  );
};

export default DailyNoticesPage;