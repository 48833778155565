import React from "react";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../hooks/user.actions";

function ProfileDetails(props) {
  const { user } = props;
  const navigate = useNavigate();

  if (!user) {
    return (
      <div className="w-full max-w-2xl mx-auto p-6 space-y-6 animate-pulse">
        <div className="flex items-center space-x-6">
          <div className="rounded-full bg-base-300 h-24 w-24"></div>
          <div className="space-y-3">
            <div className="h-4 bg-base-300 rounded w-48"></div>
            <div className="h-4 bg-base-300 rounded w-32"></div>
          </div>
        </div>
        <div className="h-48 bg-base-300 rounded"></div>
      </div>
    );
  }

  const membershipStatus = user.membership.status.toLowerCase();
  const statusColors = {
    active: "text-success bg-success/20",
    pending: "text-warning bg-warning/20",
    expired: "text-error bg-error/20",
    default: "text-base-content bg-base-200"
  };

  const statusColor = statusColors[membershipStatus] || statusColors.default;

  return (
    <div className="w-full max-w-2xl mx-auto p-4">
      <div className="bg-base-100 rounded-xl shadow-lg overflow-hidden">
        {/* Header Section */}
        <div className="bg-gradient-to-r from-primary/10 via-primary/5 to-base-100 p-6">
        {/* <div className="bg-gradient-to-r from-primary/10 to-primary/5 p-6"> */}
          <div className="flex flex-col items-center">
            <div className="relative">
              <img
                src="/user.png"
                className="w-32 h-32 rounded-full border-4 border-base-100 shadow-lg"
                alt="Avatar"
              />

            </div>
            <h2 className="mt-4 text-2xl font-semibold text-base-content">{user.email}</h2>
          </div>
        </div>

        {/* Content Section */}
        <div className="p-6 space-y-6">
          {/* Membership Status */}
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold text-base-content flex items-center gap-2">
                Πληροφορίες Συνδρομής
              </h3>
              <span className={`px-3 py-1 rounded-full text-sm font-medium ${statusColor}`}>
                {user.membership.status}
              </span>
            </div>
            
            <div className="bg-base-200 rounded-lg p-4 space-y-2">
              <div className="flex justify-between text-sm">
                <span className="text-base-content/70">Ημερομηνία Έναρξης</span>
                <span className="font-medium text-base-content">
                  {new Date(user.membership.start_date).toLocaleDateString()}
                </span>
              </div>
              {user.membership.end_date && (
                <div className="flex justify-between text-sm">
                  <span className="text-base-content/70">Ημερομηνία Λήξης</span>
                  <span className="font-medium text-base-content">
                    {new Date(user.membership.end_date).toLocaleDateString()}
                  </span>
                </div>
              )}
            </div>
          </div>

          {/* Actions Section */}
          {user.id === getUser().id && (
            <div className="flex flex-col sm:flex-row gap-4 pt-4">
              <button
                onClick={() => navigate(`/profile/${user.id}/subscription/`)}
                className="btn btn-primary flex-1 gap-2 hover:translate-y-[-1px] transition-transform"
              >
                Διαχείριση Συνδρομής
              </button>
              <button
                onClick={() => navigate(`/profile/${user.id}/change-password/`)}
                className="btn btn-primary flex-1 gap-2 hover:translate-y-[-1px] transition-transform"
              >
                Αλλαγή Κωδικού
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProfileDetails;