import React from 'react';

const Cancel = () => {
    return (
        <div>
            <h1>Payment Cancelled</h1>
            <p>Your payment has been cancelled.</p>
        </div>
    );
};

export default Cancel;
