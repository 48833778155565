import React from 'react';

const Preloader = ({ 
  size = "w-20", 
  textColor = "text-base-content/60",
  borderColor = "border-primary/40",
  lineColor = "bg-base-content/20"
}) => {
  const height = size === "w-20" ? "h-24" : "h-28"; // Maintain aspect ratio

  return (
    <div className="flex flex-col items-center justify-center p-4 gap-3">
      <div className={`relative ${size} ${height} bg-base-100 rounded shadow-lg animate-pulse`}>
        {/* Header line */}
        <div className={`absolute top-3 left-3 right-3 h-2 ${lineColor} rounded animate-[pulse_2s_ease-in-out_infinite]`} />
        
        {/* Content lines */}
        <div className={`absolute top-7 left-3 right-6 h-1.5 ${lineColor} rounded animate-[pulse_2s_ease-in-out_infinite_0.5s]`} />
        <div className={`absolute top-10 left-3 right-4 h-1.5 ${lineColor} rounded animate-[pulse_2s_ease-in-out_infinite_0.7s]`} />
        
        {/* Signature area */}
        <div className={`absolute bottom-3 left-3 w-12 h-2 ${lineColor} rounded animate-[pulse_2s_ease-in-out_infinite_1.1s]`} />
        
        {/* Border overlay */}
        <div className={`absolute inset-0 border-2 ${borderColor} rounded animate-[pulse_2s_ease-in-out_infinite]`} />
      </div>
      
      <span className={`text-sm ${textColor}`}>Φόρτωση...</span>
    </div>
  );
};

export default Preloader;