import React, { useEffect, useState  } from "react";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../hooks/user.actions";
import StripeCheckout from "./StripeCheckout";
import axiosService from "../../helpers/axios";

function SubscriptionDetails({ subscription, isLoading, error }) {
  const navigate = useNavigate();
  const currentUser = getUser();
  const [plans, setPlans] = useState([]);
  const [plansError, setPlansError] = useState(null)

  useEffect(() => {
    if (subscription) {
      console.log("Subscription data:", subscription);
      console.log("Current user:", currentUser);
      console.log("Is current user:", currentUser.id === subscription.user.id);
      console.log("Is admin:", subscription.is_admin);
      console.log("Status:", subscription.status);
      console.log("Grace period end:", subscription.grace_period_end);
    }
  }, [subscription, currentUser]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axiosService.get(`${process.env.REACT_APP_API_URL}/subscription/plans`);
        setPlans(response.data);
      } catch (err) {
        setPlansError('Failed to load subscription plans');
      }
    };

    fetchPlans();
  }, []);


  const handleSubscribe = () => {
    // Implement subscription logic
    console.log("handleSubscribe");
  };

  const handleUpgrade = () => {
    // Implement upgrade logic
    console.log("handleUpgrade");
  };

  const handleCancel = () => {
    // Implement cancellation logic
    console.log("handleCancel");
  };
  
    if (isLoading) {
      return (
        <div className="w-full max-w-2xl mx-auto p-6 space-y-6 animate-pulse">
          <div className="h-8 bg-base-300 rounded w-1/3 mx-auto"></div>
          <div className="space-y-4">
            <div className="h-32 bg-base-300 rounded"></div>
            <div className="h-48 bg-base-300 rounded"></div>
          </div>
        </div>
      );
    }
  
    if (error) {
      return (
        <div className="w-full max-w-2xl mx-auto p-6">
          <div className="bg-error/10 rounded-lg p-6 text-center">
            <div className="text-error text-2xl mb-4">⚠️</div>
            <p className="text-error font-medium mb-4">Σφάλμα φόρτωσης στοιχείων συνδρομής: {error.message}</p>
            <button 
              className="btn btn-error" 
              onClick={() => window.location.reload()}
            >
              Επανάληψη
            </button>
          </div>
        </div>
      );
    }
  
    if (!subscription) {
      return (
        <div className="w-full max-w-2xl mx-auto p-6">
          <div className="bg-base-200 rounded-lg p-6 text-center">
            <p className="text-base-content/70">Δεν υπάρχουν διαθέσιμες πληροφορίες συνδρομής.</p>
          </div>
        </div>
      );
    }
  
    const isCurrentUser = currentUser.id === subscription.user.id;
    const { is_admin, status, start_date, end_date, subscription_plan, grace_period_end } = subscription;
    const isActiveOrTrial = status === 'active' || status === 'trial';
    const isGracePeriod = grace_period_end && new Date(grace_period_end) > new Date();
  
    const statusColors = {
      active: "text-success bg-success/20",
      trial: "text-info bg-info/20",
      expired: "text-error bg-error/20",
      cancelled: "text-base-content bg-base-200",
      default: "text-base-content bg-base-200"
    };
  
    // Greek status translations
    const statusTranslations = {
      active: "Ενεργή",
      trial: "Δοκιμαστική",
      expired: "Έληξε",
      cancelled: "Ακυρώθηκε"
    };
  
    const statusColor = statusColors[status.toLowerCase()] || statusColors.default;
    const translatedStatus = statusTranslations[status.toLowerCase()] || status;
  
    return (
      <div className="w-full max-w-2xl mx-auto p-4">
        <div className="bg-base-100 rounded-xl shadow-lg overflow-hidden">
          {/* Header Section */}
          <div className="bg-gradient-to-r from-primary/10 via-primary/5 to-base-100 p-6">
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 rounded-full bg-primary/10 flex items-center justify-center text-2xl">
                💳
              </div>
              <h2 className="mt-4 text-2xl font-semibold text-base-content">Στοιχεία Συνδρομής</h2>
              <div className={`mt-2 px-4 py-1 rounded-full text-sm font-medium ${statusColor}`}>
                {translatedStatus}
              </div>
            </div>
          </div>
  
          {/* Content Section */}
          <div className="p-6 space-y-6">
            {/* User Info */}
            <div className="bg-base-200 rounded-lg p-4">
              <h3 className="text-lg font-semibold mb-4">Στοιχεία Λογαριασμού</h3>
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <span className="text-base-content/70">Email</span>
                  <span className="font-medium">{subscription.user.email}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-base-content/70">Τύπος Λογαριασμού</span>
                  <span className="font-medium">{is_admin ? "Διαχειριστής" : "Τυπικός Χρήστης"}</span>
                </div>
              </div>
            </div>
  
            {/* Plan Details */}
            {subscription_plan && (
              <div className="bg-base-200 rounded-lg p-4">
                <h3 className="text-lg font-semibold mb-4">Λεπτομέρειες Πλάνου</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <div className="flex justify-between items-center">
                      <span className="text-base-content/70">Όνομα Πλάνου</span>
                      <span className="font-medium">{subscription_plan.name}</span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-base-content/70">Τιμή</span>
                      <span className="font-medium">${subscription_plan.price}</span>
                    </div>
                  </div>
                  <div className="space-y-2">
                    <div className="flex justify-between items-center">
                      <span className="text-base-content/70">Διάρκεια</span>
                      <span className="font-medium">{subscription_plan.duration_days} ημέρες</span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-base-content/70">Μέγιστες Κατηγορίες</span>
                      <span className="font-medium">{subscription_plan.max_categories}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
  
            {/* Dates Section */}
            <div className="bg-base-200 rounded-lg p-4">
              <h3 className="text-lg font-semibold mb-4">Σημαντικές Ημερομηνίες</h3>
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <span className="text-base-content/70">Ημερομηνία Έναρξης</span>
                  <span className="font-medium">{new Date(start_date).toLocaleDateString()}</span>
                </div>
                {end_date && (
                  <div className="flex justify-between items-center">
                    <span className="text-base-content/70">Ημερομηνία Λήξης</span>
                    <span className="font-medium">{new Date(end_date).toLocaleDateString()}</span>
                  </div>
                )}
                {isGracePeriod && (
                  <div className="flex justify-between items-center text-warning">
                    <span>Λήξη Περιόδου Χάριτος</span>
                    <span className="font-medium">{new Date(grace_period_end).toLocaleDateString()}</span>
                  </div>
                )}
              </div>
            </div>
  
            {/* Actions Section */}
            {isCurrentUser && !is_admin && (
              <div className="space-y-4">
                {isActiveOrTrial || isGracePeriod ? (
                  <div className="flex flex-col sm:flex-row gap-4">
                    <button 
                      className="btn btn-primary flex-1"
                      onClick={handleUpgrade}
                    >
                      Αναβάθμιση Πλάνου
                    </button>
                    <button 
                      className="btn btn-error flex-1"
                      onClick={handleCancel}
                    >
                      Ακύρωση Συνδρομής
                    </button>
                  </div>
                ) : null}
              </div>
            )}
  
            {/* Grace Period Warning */}
            {isGracePeriod && (
              <div className="bg-warning/20 text-warning rounded-lg p-4">
                <p className="text-sm">
                  ⚠️ Η συνδρομή σας βρίσκεται σε περίοδο χάριτος. Παρακαλούμε ενημερώστε τον τρόπο πληρωμής σας για να συνεχίσετε την υπηρεσία.
                </p>
              </div>
            )}
  
            {/* Available Plans */}
            {isCurrentUser && !is_admin && (
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">Διαθέσιμα Πλάνα</h3>
                {plansError ? (
                  <div className="bg-error/10 text-error rounded-lg p-4">
                    {plansError}
                  </div>
                ) : (
                  <div className="grid grid-cols-1 gap-4">
                    {plans.map(plan => (
                      <StripeCheckout key={plan.id} plan={plan} />
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  
  export default SubscriptionDetails;