import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Make sure you have axios configured with your base URL and auth headers
import { loadStripe } from '@stripe/stripe-js';
import axiosService from '../../helpers/axios';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const StripeCheckout = ({ plan }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleCheckout = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axiosService.post(`${process.env.REACT_APP_API_URL}/stripe/create-checkout-session/`, {
        plan_id: plan.id
      });

      const { sessionId } = response.data;

      // Redirect to Stripe Checkout
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      await stripe.redirectToCheckout({ sessionId });
    } catch (err) {
      setError(err.response?.data?.detail || 'An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-4">
      <h3 className="text-lg font-semibold">{plan.name}</h3>
      <p className="text-gray-600">${plan.price} / month</p>
      <button 
        className={`btn btn-primary mt-2 ${isLoading ? 'loading' : ''}`}
        onClick={handleCheckout}
        disabled={isLoading}
      >
        {isLoading ? 'Processing...' : 'Subscribe'}
      </button>
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default StripeCheckout;