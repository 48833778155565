import React, { useState } from 'react';

const NoticeCard = ({ notice, index, bgClass }) => {
  const [copiedId, setCopiedId] = useState(null);

  const formatPrice = (price) => {
    if (!price) return '';
    const numPrice = typeof price === 'string' ? parseFloat(price) : price;
    return new Intl.NumberFormat('el-GR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(numPrice);
  };

  const copyToClipboard = (text, id) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedId(id);
      setTimeout(() => setCopiedId(null), 2000);
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  const ClipboardIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
      <path fillRule="evenodd" d="M15.988 3.012A2.25 2.25 0 0 1 18 5.25v6.5A2.25 2.25 0 0 1 15.75 14H13.5V7A2.5 2.5 0 0 0 11 4.5H8.128a2.252 2.252 0 0 1 1.884-1.488A2.25 2.25 0 0 1 12.25 1h1.5a2.25 2.25 0 0 1 2.238 2.012ZM11.5 3.25a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 .75.75v.25h-3v-.25Z" clipRule="evenodd" />
      <path fillRule="evenodd" d="M2 7a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7Zm2 3.25a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Zm0 3.5a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
    </svg>
  );

  return (
    <div className={`notice-list p-0 my-0 ${bgClass}`}>
      {index === 0 && (
        <h3 className="text-md category-titles bg-info text-primary-content font-semibold px-4 py-1 w-fit">
          {notice.category.name}
        </h3>
      )}
      <div className="p-4">
        <h3 className="text-lg font-semibold">{notice.subject}</h3>
        <p>{notice.description}</p>
        <p><strong>Φορέας:</strong> {notice.organization_name}</p>
        <p><strong>Αναρτήθηκε:</strong> {notice.published_date}</p>
        <p><strong className="mr-1 block md:inline">Καταληκτική Η/νία Υποβολής Προσφορών:</strong> {notice.deadline}</p>
        <p><strong>Τυπος δημοσίευσης:</strong> {notice.source}</p>
        <p><strong>Συνολικό Κόστος χωρίς ΦΠΑ:</strong> {formatPrice(notice.total_cost_excl_vat)}</p>
        <p className="block md:flex justify-start items-center">
          <strong className="mr-1 block md:inline">Μοναδικός Κωδικός - ΑΔΑΜ:</strong> {notice.identifier}
          <button 
            title="Αντιγραφή"
            onClick={() => copyToClipboard(notice.identifier, notice.id)}
            className="btn bg-transparent border-none btn-xs ml-1 text-info relative top-[4px] md:top-0"
            aria-label="Copy identifier to clipboard"
          >
            <ClipboardIcon />
            {copiedId === notice.id && (
              <span className="absolute top-[-30px] left-[-10px] bg-success text-primary-content px-2 py-1 rounded text-xs">
                Copied!
              </span>
            )}
          </button>
        </p>
        <p><strong>Περιγραφή με όρους CPVS:</strong> {notice.category.name} ({notice.category.cpv_code})</p>
        <p><strong>Αριθμός Πρωτοκόλλου:</strong> {notice.protocol_number}</p>
        <p>
          <strong>Πηγή:</strong>{" "}
          <a 
            href="https://cerpp.eprocurement.gov.gr/upgkimdis/unprotected/home.xhtml"
            target="_blank"
            rel="noopener noreferrer"
            className="link link-primary text-info"
          >
            ΚΗΜΔΗΣ
          </a>
        </p>
      </div>
    </div>
  );
};

export default NoticeCard;