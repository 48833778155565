import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getUser, useUserActions } from "../hooks/user.actions";
import LoginForm from "../components/authentication/LoginForm";

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const userActions = useUserActions();

  useEffect(() => {
    const checkUser = async () => {
      const localUser = getUser();
      if (!localUser) {
        setLoading(false);
        return;
      }

      try {
        const updatedUser = await userActions.fetchCurrentUser();
        setUser(updatedUser);
        if (updatedUser.is_verified) {
          navigate("/");
        } else {
          navigate("/verify-email-notice/");
        }
      } catch (error) {
        setUser(null);
      }
      setLoading(false);
    };

    checkUser();
  }, [userActions, navigate]);

  if (loading) {
    return (
      <div className="min-h-screen bg-base-300 flex items-center justify-center">
        <div className="w-full max-w-2xl mx-auto p-6 space-y-6 animate-pulse">
          <div className="h-8 bg-base-300 rounded w-1/3 mx-auto"></div>
          <div className="space-y-4">
            <div className="h-32 bg-base-300 rounded"></div>
            <div className="h-48 bg-base-300 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  if (user && user.is_verified) {
    return null;
  }

  return (
    <div className="min-h-screen bg-base-300 flex items-center justify-center p-4">
      <div className="w-full max-w-5xl">
        <div className="bg-base-100 rounded-xl shadow-lg overflow-hidden">
          <div className="bg-gradient-to-r from-primary/10 via-primary/5 to-base-100 p-6 text-center">
            <div className="w-16 h-16 rounded-full bg-primary/10 flex items-center justify-center text-2xl mx-auto mb-4">
              🔑
            </div>
            <h1 className="text-2xl font-semibold text-base-content">
              Public Contracts
            </h1>
            <p className="text-base-content/70 mt-2">
              Διαχείριση Διαγωνισμών & Ειδοποιήσεων
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-8 p-6">
            {/* Welcome Message */}
            <div className="flex flex-col justify-center space-y-6">
              <div className="bg-base-200 rounded-lg p-6 space-y-4">
                <h2 className="text-xl font-semibold text-primary">
                  Καλώς ήρθατε στο Public Contracts!
                </h2>
                <p className="text-base-content/80 leading-relaxed">
                  Συνδεθείτε τώρα και ρυθμίστε τις ειδοποιήσεις σας για νέους διαγωνισμούς!
                </p>
                <div className="pt-4 border-t border-base-300">
                  <p className="text-sm text-base-content/70">
                    Δεν έχετε λογαριασμό;{" "}
                    <Link 
                      to="/register/" 
                      className="text-primary hover:text-primary/80 font-medium transition-colors"
                    >
                      Εγγραφείτε εδώ
                    </Link>
                  </p>
                </div>
              </div>

              {/* Features */}
              <div className="grid gap-4">
                <div className="bg-base-200 rounded-lg p-4 hidden md:block">
                  <h3 className="font-medium text-base-content mb-2">🔔 Ειδοποιήσεις</h3>
                  <p className="text-sm text-base-content/70">
                    Λάβετε άμεσες ειδοποιήσεις για νέους διαγωνισμούς
                  </p>
                </div>
                <div className="bg-base-200 rounded-lg p-4 hidden md:block">
                  <h3 className="font-medium text-base-content mb-2">📋 Διαχείριση</h3>
                  <p className="text-sm text-base-content/70">
                    Οργανώστε και παρακολουθήστε τους διαγωνισμούς σας
                  </p>
                </div>
                {/* <div className="bg-base-200 rounded-lg p-4">
                  <h3 className="font-medium text-base-content mb-2">📊 Αναφορές</h3>
                  <p className="text-sm text-base-content/70">
                    Πρόσβαση σε αναλυτικά στατιστικά και αναφορές
                  </p>
                </div> */}
              </div>
            </div>

            {/* Login Form */}
            <div className="bg-base-200 rounded-lg p-6">
              <h2 className="text-lg font-semibold text-base-content mb-6">
                Σύνδεση
              </h2>
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;