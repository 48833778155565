import React, { useState } from "react";
import axios from "axios";

function ForgotPasswordForm() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/password-reset/`, { email });
      setMessage("Το email eπαναφοράς κωδικού στάλθηκε στο mail σας");
      setError(null);
    } catch (err) {
      setError("Αποτυχία αποστολής email επαναφοράς");
      setMessage("");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 rounded">
      <div className="mb-3">
        <label className="block text-sm font-medium">Email</label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          type="email"
          placeholder="Εισαγωγή email"
          className="mt-1 block w-full px-3 py-2 border  border-primary"
        />
      </div>
      {message && <div className="text-green-500 text-sm mt-1">{message}</div>}
      {error && <div className="text-red-500 text-sm mt-1">{error}</div>}
      <button
        type="submit"
        className="btn btn-info mt-3 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Αποστολή Εmail Επαναφοράς
      </button>
    </form>
  );
}

export default ForgotPasswordForm;
