import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useUserActions } from "../../hooks/user.actions";


function RegistrationForm() {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [form, setForm] = useState({
    username: "",
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    bio: "",
  });
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false); // New state for password visibility
  const userActions = useUserActions();
  const [captchaValue, setCaptchaValue] = useState(null);
  const recaptchaRef = useRef();
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY

  const handleSubmit = (event) => {
    event.preventDefault();
    const registrationForm = event.currentTarget;

    if (registrationForm.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);

    if (!captchaValue) {
      setError("Παρακαλώ συμπληρώστε το CAPTCHA");
      return;
    }

    const data = {
      username: form.username,
      password: form.password,
      email: form.email,
      first_name: form.first_name,
      last_name: form.last_name,
      bio: form.bio,
      captcha: captchaValue,
    };

    userActions.register(data).catch((err) => {
      if (err.message) {
        setError(err.request.response);
      }
    });

    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/register/`, data)
      .then((res) => {
        // Registering the account and tokens in the
        // store
        localStorage.setItem(
          "auth",
          JSON.stringify({
            access: res.data.access,
            refresh: res.data.refresh,
            user: res.data.user,
          })
        );
        navigate("/");
      })
      .catch((err) => {
        if (err.message) {
          setError(err.request.response);
        }
      });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };


  return (
    <form
      id="registration-form"
      className=" p-4 rounded"
      noValidate
      onSubmit={handleSubmit}
      data-testid="register-form"
    >
      <div className="mb-3">
        <label className="block text-sm font-medium">Email</label>
        <input
          value={form.email}
          data-testid="email-field"
          onChange={(e) => setForm({ ...form, email: e.target.value })}
          required
          type="email"
          placeholder="Εισαγωγή Email"
          className="mt-1 block w-full px-3 py-2 border  border-primary"
        />
        <div className="hidden text-red-500 text-sm mt-1">Παρακαλώ εισάγετε ένα έγκυρο Email</div>
      </div>

      <div className="mb-3 relative">
        <label className="block text-sm font-medium">Password</label>
        <input
          value={form.password}
          data-testid="password-field"
          minLength="8"
          onChange={(e) => setForm({ ...form, password: e.target.value })}
          required
          type={showPassword ? "text" : "password"} // Toggle between text and password
          placeholder="Εισαγωγή Κωδικού"
          className="mt-1 block w-full px-3 py-2 border  border-primary"
        />
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className="absolute inset-y-0 right-0 pr-3 top-6 bflex items-center text-sm leading-5 text-primary"
        >
          {showPassword ? "Απόκρυψη" : "Εμφάνιση"}
        </button>
        <div className="hidden text-red-500 text-sm mt-1">
          Παρακαλώ εισάγετε έναν έγκυρο κωδικό πρόσβασης
        </div>
      </div>
      <div className="mb-3">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LezTwkqAAAAAEMxTM2tcloUit_hKEQ5ki7X8o7B"
          onChange={handleCaptchaChange}
        />
      </div>

      <div className="text-content text-red-500">{error && <p>{error}</p>}</div>

      <button
        type="submit"
        className="btn btn-info font-bold py-2 px-4 rounded w-full"
      >
        Εγγραφή
      </button>
    </form>
  );
}

export default RegistrationForm;
